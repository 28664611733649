/* eslint-disable arrow-body-style */
import { postFormDataMethod, getMethod } from "../apiConfig";

export const getProfileApi = (data) => {
    return getMethod('/superadmin/get-profile-details', data);
};

export const updateProfileApi = (data) => {
    return postFormDataMethod('/superadmin/update-profile', data);
}
/* eslint-disable arrow-body-style */
